import axios from 'axios'
import React, { useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { Header } from './header'

export const NewUsuarioDashboard = () => {
    const history = useHistory()
    const [data, setData] = useState({
        nombre: '',
        usuario: '',
        email: '',
        password: '',
    })

    const handleModifyInfo = (e) =>{
        // console.log(imagen)
        // console.log('HOOK: ', imagenFile[0])
        let formData = new FormData();
    
        formData.append('nombre', data.nombre)
        formData.append('email', data.email)
        formData.append('usuario', data.usuario)
        formData.append('password', data.password)




        //formData.append('imagenesExtra', imagenesExtraString)


        axios({
          method: 'post',
          params:{action:"postUsuario"},
          url: 'https://dashboard.puertadehierroac.mx/dashboardScript.php',
          data: formData,
          config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(function (response) {
          //handle success
          console.log(response)
          alert("Publicado")

        })
        .catch(function (response) {
          //handle error
          console.log(response)
        });
    }

    if(sessionStorage.getItem('auth')==='true'){
        return (
            <div>
                <Header/>
                <br/>


                <div className="modificarCuenta_container">
                    <h1>Modificar Cuenta de Residente</h1>
                    <p>En esta seccion es posible verificar si una cuenta de residente sigue en existencia, ver su password o tambien modificar su password en caso de olvidarlo</p>

                    <>
                        <div className="mb-3 row">
                            <p  className="col-sm-2 labelTextRight">Nombre:</p>
                            <div className="col-sm-8">
                                <input className="form-control inputFormNewMaterial" name="description" id="description" value={data.nombre} onChange={e=>setData({...data, nombre:e.target.value})}/>
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <p  className="col-sm-2 labelTextRight">Email:</p>
                            <div className="col-sm-8">
                                <input className="form-control inputFormNewMaterial" name="description" id="description" value={data.email} onChange={e=>setData({...data, email:e.target.value})} />
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <p  className="col-sm-2 labelTextRight">Usuario:</p>
                            <div className="col-sm-8">
                                <input className="form-control inputFormNewMaterial" name="description" id="description" value={data.usuario} onChange={e=>setData({...data, usuario:e.target.value})}/>
                            </div>
                        </div>


                        <div className="mb-3 row">
                            <p  className="col-sm-2 labelTextRight">Password:</p>
                            <div className="col-sm-8">
                                <input className="form-control inputFormNewMaterial" name="description" id="description" value={data.password} onChange={e=>setData({...data, password:e.target.value})} />
                            </div>
                        </div>

                        <div className='row col-12 botonesModificar'  >
                            <div className="mb-3 col-6 row">
                                <button className='col-5 me-4' onClick={e=>handleModifyInfo()} >Crear Usuario</button>
                                <button className='col-5 me-4' onClick={history.goBack} >Cancelar</button>
                                
                            </div>




                        </div>



                    </>






                    </div>
            </div>
        )


    }
    else{
        return(
            <Redirect to={'/'}/>
        )
    }

}
