import React, { useState, Component  } from 'react';
import { Header } from './header'
import { Button, Col, Form, Row } from 'react-bootstrap'
import {Link, Redirect} from 'react-router-dom'
import axios from 'axios';
import ImageUpload from './assets/icons/image-upload.png'




export const ModificarCuenta = () => {

    const [search, setSearch] = useState('')
    const [founded, setFounded] = useState(false)
    const [newPass, setNewPass] = useState('')
    const [changePass, setChangePass] = useState(false)
    const [data, setData] = useState({
        nombre: '',
        idUsuario: '',
        email: '',
        telefono: '',
        celular: '',
        estatus: '',
        perfil: '',
        usuario:''

    })

    const handleModifyInfo = (e) =>{
        let formData = new FormData();
    
        formData.append('email', data.email)
        formData.append('telefono', data.telefono)
        formData.append('celular', data.celular)
        // formData.append('nombre', data.nombre)
        // formData.append('usuario', data.usuario)


        console.log(formData)



        axios({
            method: 'post',
            
            url: `https://sac14.com.mx/app/puertahierro/usuarios/actualizar_usuario/${data.idUsuario}`,
            data: formData,
            headers: {
                'X-API-KEY': '1af0d480c2ad84891b106a057b130013'
            }
          })
          .then(function (response) {
            //handle success
            console.log(response)
            alert("Noticia Publicada")

          })
          .catch(function (response) {
            //handle error
            console.log(response)
          });
    }

    const handleModifyPass = (e) =>{
        let formData = new FormData();
    
        formData.append('passwd', newPass)

        



        axios({
            method: 'post',
            
            url: `https://sac14.com.mx/app/puertahierro/usuarios/actualizar_passwd/${data.idUsuario}`,
            data: formData,
            headers: {
                'X-API-KEY': '1af0d480c2ad84891b106a057b130013',
                "Content-Type": "multipart/form-data" ,
            }
          })
          .then(function (response) {
            //handle success
            console.log(response)
            alert("Contrasena cambiada")

          })
          .catch(function (response) {
            //handle error
            console.log(response)
          });
    }


    const handleSearch = (e) =>{
        let formData = new FormData();
    



        //formData.append('imagenesExtra', imagenesExtraString)


        axios.get(`https://sac14.com.mx/app/puertahierro/usuarios/buscarXcontrato/${search}`, {
            headers: {
                'X-API-KEY': '1af0d480c2ad84891b106a057b130013'
            }
        })
        .then(function (response) {
          //handle success
          console.log(response)

          setData({...data, nombre:response.data.usuario.nombre, idUsuario:response.data.usuario.id_usuario, email:response.data.usuario.email, telefono:response.data.usuario.telefono, celular:response.data.usuario.celular, estatus: response.data.usuario.estatus, perfil:response.data.usuario.perfil, usuario:response.data.usuario.usuario})
          alert("encontrado")
          setFounded(true)

        })
        .catch(function (response) {
          //handle error
          console.log(response)
        });

    }


    if(sessionStorage.getItem('auth')==='true'){
        return (

            <div>
                <Header/>
                <div className="modificarCuenta_container">
                <h1>Modificar Cuenta de Residente</h1>
                <p>En esta seccion es posible verificar si una cuenta de residente sigue en existencia, ver su password o tambien modificar su password en caso de olvidarlo</p>

                    <div className='row busquedaResidente'>
                        <input className='col-9' placeholder='ingrese un numero de contrato' value={search} onChange={e=>setSearch(e.target.value)} />
                        <button className='col-2 ms-4' onClick={e=>handleSearch()} >Buscar</button>
                    </div>

                    <br/>
                    <br/>
                    <br/>

                    {founded?(
                        <>
                            <div className="mb-3 row">
                                <p  className="col-sm-1 labelTextRight">Nombre:</p>
                                <div className="col-sm-8">
                                    <input className="form-control inputFormNewMaterial" name="description" id="description" disabled value={data.nombre} />
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <p  className="col-sm-1 labelTextRight">Usuario:</p>
                                <div className="col-sm-8">
                                    <input className="form-control inputFormNewMaterial" name="description" id="description" disabled value={data.idUsuario} />
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <p  className="col-sm-1 labelTextRight">Email:</p>
                                <div className="col-sm-8">
                                    <input className="form-control inputFormNewMaterial" name="description" id="description" value={data.email} onChange={e=>setData({...data, email:e.target.value})} />
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <p  className="col-sm-1 labelTextRight">Telefono:</p>
                                <div className="col-sm-8">
                                    <input className="form-control inputFormNewMaterial" name="description" id="description" value={data.telefono} onChange={e=>setData({...data, telefono:e.target.value})} />
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <p  className="col-sm-1 labelTextRight">Celular:</p>
                                <div className="col-sm-8">
                                    <input className="form-control inputFormNewMaterial" name="description" id="description" value={data.celular} onChange={e=>setData({...data, celular:e.target.value})} />
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <p  className="col-sm-1 labelTextRight">Estatus:</p>
                                <div className="col-sm-8">
                                    <input className="form-control inputFormNewMaterial" name="description" id="description" disabled value={data.estatus} />
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <p  className="col-sm-1 labelTextRight">Perfil:</p>
                                <div className="col-sm-8">
                                    <input className="form-control inputFormNewMaterial" name="description" id="description" disabled value={data.perfil} />
                                </div>
                            </div>

                            <div className='row col-12 botonesModificar'  >
                                <div className="mb-3 col-6 row">
                                    <button className='col-5 me-4' onClick={e=>handleModifyInfo()} >Modificar Informacion</button>
                                    <button className='col-5' onClick={e=>setChangePass(true)}>Modificar Password</button>
                                </div>

                                {changePass?(
                                    <div className="col-6" >
                                        <input className='col-8' placeholder="Ingrese el nuevo password" value={newPass} onChange={e=>setNewPass(e.target.value)} />
                                        <button onClick={e=>handleModifyPass()} className='ms-4'>Cambiar Password</button>
                                    </div>
                                ):(null)}


                            </div>



                        </>




                    ):(
                        <h1>Ningun Residente Encontrado</h1>
                    )}

                </div>
                
            </div>

        )  
    }
    else{
        return(
            <Redirect to={'/'}/>
        )
    }

}
