import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import './App.css'

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      redirectToReferrer: false
    };
    this.login = this.login.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  login() {
    if(this.state.username && this.state.password){
      var formData = new FormData();
      var self = this;

      formData.append('userName', this.state.username);
      formData.append('passWord', this.state.password);

      console.log('user: '+this.state.username)
      console.log('pass: '+this.state.password)

      axios({
        method: 'post',
        params:{action:"login"},
        url: 'https://dashboard.puertadehierroac.mx/dashboardScript.php',
        data: formData,
        config: { headers: {'Content-Type': 'multipart/form-data' }}
      })
      .then(function (response) {

        console.log(response)

        if(response.data != "El usuario no existe"){
          alert('Bienvenido')
          sessionStorage.setItem('auth', "true")
          

          self.setState({
            redirectToReferrer:true
          })
        }else{
          alert(response.data)
        }

      })
      .catch(function (response) {
        //handle error
        console.log(response)
      });
    }
  }


  onChange(e){
    this.setState({[e.target.name]:e.target.value});
  }

  componentWillMount(){
    sessionStorage.removeItem('auth')
  }

  render() {
    if (this.state.redirectToReferrer){
      return (
        <Redirect to={'/home'}/>
      );
    }

    return (
      <div className="loginContainer">
        <div className="coverLogin">
        </div>
        <div className="loginForm">
          <div className="inputsContainer">
            <img src="http://puertadehierroac.mx/imagenes/iconos/logoBlancoLogin.png"/>
            <h1>Puerta de Hierro</h1>
            <h2>Panel de Control Web</h2>
            <input onChange={this.onChange} name="username" type="text" placeholder="usuario"/>
            <input onChange={this.onChange} name="password" type="password" placeholder="contraseña"/>
            <button onClick={this.login}>INGRESAR</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;