import axios from 'axios';
import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom';
import AvancesCard from './avancesCard';
import { Header } from './header'

export const EliminarAvances = () => {
    
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [imgModal, setImgModal] = React.useState("")
    const [loaded, setLoaded] = React.useState(false);
    const [obras, setObras] = React.useState([])
    const [obrasSeguridad, setObrasSeguridad] = React.useState([])
    const [obrasJardineria, setObrasJardineria] = React.useState([])
    const [obrasMantenimiento, setObrasMantenimiento] = React.useState([])
    const [obrasAdministracion, setObrasAdministracion] = React.useState([])
  


    useEffect(() => {
        const url = 'https://dashboard.puertadehierroac.mx/dashboardScript.php'
        axios.get(url, {params:{action:"getAvances"}}).then(response => response.data)
        .then((data) => {
          //console.log(data)
    
            setObras(data)
            var obrasInversionLocal = data.filter((obra) => obra.area === '1')
            var obrasSeguridadLocal = data.filter((obra) => obra.area === '2')
            var obrasJardineriaLocal = data.filter((obra) => obra.area === '3')
            var obrasMantenimientoLocal = data.filter((obra) => obra.area === '4')
            var obrasAdministracionLocal = data.filter((obra) => obra.area === '5')
    

    
            setLoaded(true)
            
            console.log('obrasInversion: ', obrasInversionLocal)
            console.log('obrasSeguridad: ', obrasSeguridadLocal)
            console.log('obrasJardineria: ', obrasJardineriaLocal)
            console.log('obrasMantenimiento: ', obrasMantenimientoLocal)
            console.log('obrasAdministracion: ', obrasAdministracionLocal)

    
        })
      }, [])


      if(sessionStorage.getItem('auth')==='true'){
        return (
            <div className='eliminarAvances_container'>
                <Header/>
                <br/>
                <br />
                <br />
                <br />
                <br />
                <br />


                <div className="obras-container">        
                    <div className="row">
                    {obras.map(function(item){   
                    
                    return<AvancesCard
                        id={item.id}
                        title={item.titulo}
                        date={item.fecha}
                        image={item.imagen1}
                        image2={item.imagen2}
                        image3={item.imagen3}
                        description={item.descripcion}
                        />
                        
                    }) 


                    }
                    </div>
                </div>
                
            </div>
        ) 
      }
      else{
          return(
              <Redirect to={'/'}/>
          )
      }

}
