import logo from './logo.svg';
import RouterPrincipal from './routerPrincipal'
import './App.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import 'semantic-ui-css/semantic.min.css'

function App() {
  return (
    <div className="App">
      <RouterPrincipal/>
    </div>
  );
}

export default App;
