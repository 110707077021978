import React, { useState, Component  } from 'react';
import { Header } from './header'
import { Button, Col, Form, Row } from 'react-bootstrap'
import ImageUpload from './assets/icons/image-upload.png'
import { withStyles } from "@material-ui/core/styles";
import {Link, Redirect} from 'react-router-dom'
import axios from 'axios';




import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Carousel from 'react-bootstrap/Carousel'


const styles = theme => ({
    root: {
      maxWidth: 345,
      margin:"3%",
      marginLeft:"auto",
      marginRight:"auto",
      boxShadow: "0 .5rem 3rem #926123"
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },

});


class AvancesForm extends Component {



    // const [titulo, setTitulo] = useState('')
    // const [fecha, setFecha] = useState('')
    // const [area, setArea] = useState('')
    // const [imagenes, setImagenes] = useState([])
    // const [renderImagesList, setRenderImagesList] = useState(false)
    // const [descripcion, setDescripcion] = useState('')
    constructor(){
        super();
        this.state={
            titulo:"",
            fecha:"",
            area:"",
            imagen1:"",
            imagen2:"",
            imagen3:"",
            imagenFile1:"",
            imagenFile2:"",
            imagenFile3:"",
            renderImagesList:false,
            descripcion:""
    
        }
        this.handleImages = this.handleImages.bind(this);
        this.handlePost = this.handlePost.bind(this);
      }



    handleImages (e){

        var arrayImagesPreview = []

        console.log(e.target.files)

        if(e.target.files.length > 3){
            alert("solo puedes agregar 3 imagenes")
        }else{
            this.setState({
                imagen1:URL.createObjectURL(e.target.files[0]) ,
                imagen2:URL.createObjectURL(e.target.files[1]) ,
                imagen3:URL.createObjectURL(e.target.files[2]),
                imagenFile1:e.target.files[0],
                imagenFile2:e.target.files[1],
                imagenFile3:e.target.files[2],
                renderImagesList:true
            })
        }
    }



    handlePost(evt){
        var imagen = this.state.imgPrincipal
        var imagenesExtra = this.state.imgsExtras
        var imagenesExtraString = JSON.stringify(imagenesExtra)
        var titulo = this.state.valueTitulo
        var pathTitulo = this.state.pathTitulo
        var contenido = this.state.contenido
        var stringContenido = JSON.stringify(contenido)
        var objNoticia
        var self=this
    

        // console.log('imagen1: ', this.state.imagenFile1)
        // console.log('imagen2: ', this.state.imagenFile2)
        // console.log('imagen3: ', this.state.imagenFile3)
    
        
        let formData = new FormData();

        formData.append('titulo', this.state.titulo)
        formData.append('fecha', this.state.fecha)
        formData.append('area', this.state.area)
        formData.append('imagen1', this.state.imagenFile1)
        formData.append('imagen2', this.state.imagenFile2)
        formData.append('imagen3', this.state.imagenFile3)
        formData.append('descripcion', this.state.descripcion)



        //formData.append('imagenesExtra', imagenesExtraString)


        axios({
        method: 'post',
        params:{action:"postAvance"},
        url: 'https://dashboard.puertadehierroac.mx/dashboardScript.php',
        data: formData,
        config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(function (response) {
        //handle success
        console.log(response)
        alert("Noticia Publicada")
            self.setState({
                titulo:"",
                fecha:"",
                area:"",
                imagen1:"",
                imagen2:"",
                imagen3:"",
                imagenFile1:"",
                imagenFile2:"",
                imagenFile3:"",
                renderImagesList:false,
                descripcion:""
            })
        })
        .catch(function (response) {
        //handle error
        console.log(response)
        });


    
      }
    

    render(){



    
        const {classes} = this.props;

        if(sessionStorage.getItem('auth')==='true'){
            return (

                <div>
                    <Header/>

                    <div className="introduccion-container" >
                        <h1>Avances</h1>
                        <p>En este apartado podras publicar un nuevo elemento en el apartado de avances, tales como: obras realizadas, nuevas tecnologias implementadas, acontecimientos importantes, etc</p>
                        <p>INSTRUCCIONES</p>
                        <p>1-Del lado derecho podrias ir capturando los distintos datos que lleva el componente</p>
                        <p>2-Del lado izquierdo podras ir viendo el resutlado final en tiempo real conforme capturas los datos</p>
                        <p>3-Presional boton publicar una vez que estes convencido de la informacion capturada, de lo contrario presiona el boton cancelar.</p>
                    </div>

                    <div className="avances_container">

                        <div className="form_avances">
                            <Form  >

                                <Col xs={12} md={12} >
                                    <Form.Group>
                                        <Form.Label>Titulo</Form.Label>
                                        <Form.Control placeHolder="Escribe un titulo" type="text" onChange={e => this.setState({titulo:e.target.value })}  />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} >
                                    <Form.Group>
                                        <Form.Label>Fecha</Form.Label>
                                        <Form.Control placeHolder="Usa el formato dd/mm/aaaa" type="text" onChange={e => this.setState({fecha:e.target.value })}  />

                                    </Form.Group>
                                </Col>

                                <Col xs={12} md={12} >
                                    <Form.Group>
                                        <Form.Label>Area</Form.Label>
                                        <Form.Control as="select" onChange={e => this.setState({area:e.target.value })} >
                                            <option>Seleccione un area</option>
                                            <option value="1">Inversion</option>
                                            <option value="2">Seguridad</option>
                                            <option value="3">Jardineria</option>
                                            <option value="4">Mantenimiento</option>
                                            <option value="5">Administracion</option>
                                        </Form.Control>

                                    </Form.Group>
                                </Col>

                                <Col xs={12} md={12} >
                                    <Form.Group>

                                        {this.state.renderImagesList?
                                            <div className="imagesPreview_container">
                                                <img src={this.state.imagen1} />
                                                <img src={this.state.imagen2} />
                                                <img src={this.state.imagen3} />
                                            </div>
                                    
                                        :
                                        <div class="fileDropArea_uploadExcel">
                                            <img src={ImageUpload} /><br/>
                                            <h3>Imagenes</h3>
                                            <h5>Agrega un maximo de 3 imagenes</h5>
                                            <input class="file-input" type="file" onChange={this.handleImages.bind(this)} accept="image/*" multiple/>
                                        </div>

                                    
                                        }


                                        
                                    </Form.Group>
                                </Col>


                                <Col xs={12} md={12} >
                                    <Form.Group>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control as="textarea" rows={3} onChange={e => this.setState({descripcion:e.target.value })}   />
                                    </Form.Group>
                                </Col>
                                    
                                
                            </Form>
                        </div>
                        
                        <div className="previewCard_avances">
                            <Card className={classes.root}>

                            <CardHeader
                                avatar={
                                <Avatar aria-label="recipe" className={classes.avatar}>
                                    PH
                                </Avatar>
                                }

                                title={this.state.titulo}
                                subheader={this.state.fecha}
                            />


                            <Carousel indicators={false}>
                                <Carousel.Item>
                                <img
                                    className="d-block w-100 cardImage"
                                    src={this.state.imagen1}
                                    alt="First slide"
                                />
                                </Carousel.Item>

                                <Carousel.Item>
                                <img
                                    className="d-block w-100 cardImage"
                                    src={this.state.imagen2}
                                    alt="Second slide"
                                />

                                </Carousel.Item>

                                <Carousel.Item>
                                <img
                                    className="d-block w-100 cardImage"
                                    src={this.state.imagen3}
                                    alt="Third slide"
                                />


                                </Carousel.Item>
                            </Carousel>




                            <CardContent>
                                <Typography id="cardDescription" variant="body2" color="textSecondary" component="p">
                                    {this.state.descripcion}
                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing>
                                <IconButton aria-label="add to favorites">
                                <FavoriteIcon />
                                </IconButton>
                                <IconButton aria-label="share">
                                <ShareIcon />
                                </IconButton>

                            </CardActions>
                            
                            </Card>
                        
                            <div className="actionButtonsAvances_container">
                                <button onClick={this.handlePost.bind(this)} id="publicarButton">Publicar</button>

                                <Link to="/home">
                                    <button id="cancelarbutton">Cancelar</button>
                                </Link>
                                
                            </div>
                        
                        </div>
                    
                    </div>
                </div>

            ) 
        }
        
        else{
            return(
               <Redirect to={'/'}/> 
            )
            
        }
 



    }
}

export default withStyles(styles, { withTheme: true })(AvancesForm);
