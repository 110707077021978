import React, { useState, Component  } from 'react';
import { Header } from './header'
import { Button, Col, Form, Row } from 'react-bootstrap'
import {Link, Redirect} from 'react-router-dom'
import axios from 'axios';
import ImageUpload from './assets/icons/image-upload.png'




export const DirectorioTelefonico = () => {
    const [nombreCaseta, setNombreCaseta] = useState('')
    const [departamento1, setDepartamento1] = useState('')
    const [telefono1, setTelefono1] = useState('')
    const [ext1, setExt1] = useState('---');
    const [departamento2, setDepartamento2] = useState('')
    const [telefono2, setTelefono2] = useState('')
    const [ext2, setExt2] = useState('---');
    const [departamento3, setDepartamento3] = useState('')
    const [telefono3, setTelefono3] = useState('')
    const [ext3, setExt3] = useState('---');
    const [imagen, setImagen] = useState('')
    const [imagenFile, setImagenFile] = useState([])




    const handleChangeImage = (e) =>{
        console.log(e.target.files[0])
        setImagenFile([...imagenFile, e.target.files[0]])
        setImagen(URL.createObjectURL(e.target.files[0]))
        
        console.log(imagen)
        console.log(imagenFile)
    }

    const handlePost = (e) =>{

        // console.log(imagen)
        // console.log('HOOK: ', imagenFile[0])
        let formData = new FormData();
    
        formData.append('nombreCaseta', nombreCaseta)
        formData.append('departamento1', departamento1)
        formData.append('telefono1', telefono1)
        formData.append('ext1', ext1)
        formData.append('departamento2', departamento2)
        formData.append('telefono2', telefono2)
        formData.append('ext2', ext2)
        formData.append('departamento3', departamento3)
        formData.append('telefono3', telefono3)
        formData.append('ext3', ext3)
        formData.append('imagen', imagenFile[0])



        //formData.append('imagenesExtra', imagenesExtraString)


        axios({
          method: 'post',
          params:{action:"postDirectorio"},
          url: 'https://dashboard.puertadehierroac.mx/dashboardScript.php',
          data: formData,
          config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(function (response) {
          //handle success
          console.log(response)
          alert("Publicado")

        })
        .catch(function (response) {
          //handle error
          console.log(response)
        });

    }


    if(sessionStorage.getItem('auth')==='true'){
        return (

            <div>
                <Header/>
                <div className="directorioTelefonico_container">
                    <h1>Directorio telefonico</h1>
                    <p>En este componente podras dar de alta nuevos numeros de las distintas casetas del fraccionamiento, con un maximo de 3 numeros y sus respectivas extensiones.</p>
                </div>

                <div className="formAndPreview_container">

                    <div className="formDirectorio_container">
                        <Form  >

                            <Col xs={12} md={12} >
                                <Form.Group>
                                    <Form.Label>Lugar</Form.Label>
                                    <Form.Control placeHolder="ej: caseta andalucia, caseta 1, etc" type="text" onChange={e => setNombreCaseta(e.target.value)}  />
                                </Form.Group>
                            </Col>

                            <Row xs={12} md={12} >

                                <Col xs={12} md={4}>
                                    <Form.Group>
                                        <Form.Label>Departamento 1</Form.Label>
                                        <Form.Control placeHolder="ej: gerencia, peatonal, etc." type="text" onChange={e => setDepartamento1(e.target.value)}  />
                                    </Form.Group>
                                </Col>  

                                <Col xs={12} md={4}>
                                    <Form.Group>
                                        <Form.Label>Telefono 1</Form.Label>
                                        <Form.Control placeHolder="Captura solo los digitos del telefono" type="text" onChange={e => setTelefono1(e.target.value)}  />
                                    </Form.Group>

                                </Col>

                                <Col xs={12} md={4}>
                                    <Form.Group>
                                        <Form.Label>Ext</Form.Label>
                                        <Form.Control placeHolder="Captura solo los digitos de la ext" type="text" onChange={e => setExt1(e.target.value)}  />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row xs={12} md={12} >
                                <Col xs={12} md={4}>
                                    <Form.Group>
                                        <Form.Label>Departamento 2</Form.Label>
                                        <Form.Control placeHolder="ej: gerencia, peatonal, etc." type="text" onChange={e => setDepartamento2(e.target.value)}  />
                                    </Form.Group>
                                </Col>  

                                <Col xs={12} md={4}>
                                    <Form.Group>
                                        <Form.Label>Telefono 2</Form.Label>
                                        <Form.Control placeHolder="Captura solo los digitos del telefono" type="text" onChange={e => setTelefono2(e.target.value)}  />
                                    </Form.Group>

                                </Col>

                                <Col xs={12} md={4}>
                                    <Form.Group>
                                        <Form.Label>Ext</Form.Label>
                                        <Form.Control placeHolder="Captura solo los digitos de la ext" type="text" onChange={e => setExt2(e.target.value)}  />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row xs={12} md={12} >
                                <Col xs={12} md={4}>
                                    <Form.Group>
                                        <Form.Label>Departamento 3</Form.Label>
                                        <Form.Control placeHolder="ej: gerencia, peatonal, etc." type="text" onChange={e => setDepartamento3(e.target.value)}  />
                                    </Form.Group>
                                </Col>  

                                <Col xs={12} md={4}>
                                    <Form.Group>
                                        <Form.Label>Telefono 3</Form.Label>
                                        <Form.Control placeHolder="Captura solo los digitos del telefono" type="text" onChange={e => setTelefono3(e.target.value)}  />
                                    </Form.Group>

                                </Col>

                                <Col xs={12} md={4}>
                                    <Form.Group>
                                        <Form.Label>Ext</Form.Label>
                                        <Form.Control placeHolder="Captura solo los digitos de la ext" type="text" onChange={e => setExt3(e.target.value)}  />
                                    </Form.Group>
                                </Col>
                            </Row>



                            <Col xs={12} md={12} >
                                <Form.Group>

                                    <div class="fileDropArea_uploadExcel">
                                        <img src={ImageUpload}/><br/>
                                        <h3>Imagen</h3>
                                        <h5>Agrega una foto para identificar la caseta o el area</h5>
                                        <input class="file-input" type="file" onChange={e=>handleChangeImage(e)} accept="image/*" multiple/>
                                    </div>

                                </Form.Group>
                            </Col>



                                

                            </Form>
                    </div>

                    <div className="previewDirectorio_container">
                        <div class="ui relaxed items">

                            <div class="item">
                                <div class="ui small image">
                                <img src={imagen?imagen:"https://firebasestorage.googleapis.com/v0/b/puertahierro-67dce.appspot.com/o/logoPuerta2.png?alt=media&token=ed22f2a2-5a78-4140-91f4-2adc401ab920"}/>
                                </div>
                                <div class="middle aligned content">
                                <div class="header">{nombreCaseta}</div>
                                <div class="description">
                                    <p> {departamento1} : <a className="enlaceTelefono" href="tel:3323019805"> <i aria-hidden="true" class="call icon"></i> Tel: {telefono1}</a> Ext: {ext1} </p>
                                    <p> {departamento2} : <a className="enlaceTelefono" href="tel:3323019805"> <i aria-hidden="true" class="call icon"></i> Tel: {telefono2}</a> Ext: {ext2} </p>
                                    <p> {departamento3} : <a className="enlaceTelefono" href="tel:3323019805"> <i aria-hidden="true" class="call icon"></i> Tel: {telefono3}</a> Ext: {ext3} </p>
                                </div>

                                </div>
                            </div>

                            <div className="actionButtonsAvances_container">
                                <button onClick={e=>handlePost()} id="publicarButton">Publicar</button>

                                <Link to="/home">
                                    <button id="cancelarbutton">Cancelar</button>
                                </Link>
                                
                            </div>
                        </div>
                    </div>

                </div>
                


                
            </div>

        )

    }

    else{
        return(
            <Redirect to={'/'}/>
        )
    }

}
