import React from 'react'

export const Header = () => {
    return (

        <div>
            <div className="header_container">
                <img src="http://puertadehierroac.mx/imagenes/iconos/logoBlancoLogin.png"/>
                
            </div>


            
        </div>

    )
}
