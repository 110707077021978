import React, { Component } from 'react';
import {Route, HashRouter, BrowserRouter, Switch,Router} from 'react-router-dom'
import  AvancesForm  from './avancesForm';
import { DirectorioTelefonico } from './directorioTelefonico';
import { EliminarAvances } from './eliminarAvances';

import Landing from './landing'
import Login from './login'
import { ModificarCuenta } from './modificarCuenta';
import { NewUsuarioDashboard } from './newUsuarioDashboard';


class RouterPrincipal extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Login}/>
            <Route path="/home" component={Landing}/>
            <Route path="/avances" component={AvancesForm}/>
            <Route path="/eliminarAvances" component={EliminarAvances}/>
            <Route path="/directorioTelefonico" component={DirectorioTelefonico}/>
            <Route path="/modificarCuenta" component={ModificarCuenta}/>
            <Route path="/newUsuario" component={NewUsuarioDashboard}/>
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default RouterPrincipal;