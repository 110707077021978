import React, { Component } from 'react';
import {Route, HashRouter, BrowserRouter, Switch,Router, Link, Redirect} from 'react-router-dom'
import { Header } from './header'





class Landing extends Component {

  componentWillUnmount(){
    console.log( 'auth: ', sessionStorage.getItem('auth'))
  }


  render() {

    if(sessionStorage.getItem('auth')==='true'){
      return (
        <div>
          <Header/>
          <div className="row landingContainer">
              <div className=" landingContenidoContainer">
                  <h1>Contenido</h1>

                  <Link to="/newUsuario">
                    <button>Usuarios</button>
                  </Link>
                  

                  <Link to="/avances">
                    <button>Avances</button>
                  </Link>

                  <Link to="/eliminarAvances">
                    <button>Eliminar Avances</button>
                  </Link>
                  
                  <Link to="/directorioTelefonico">
                    <button>Directorio telefonico</button>
                  </Link>
                  
                  <button>Galeria</button>
                  <button>Reglamentos</button>
                  
              </div>

              <div className="landingCuentasContainer">
                  <h1>Cuentas de Residentes</h1>

                  <Link to='/modificarCuenta'>
                    <button>Modificar Cuenta</button>
                  </Link>
                  
                  
              </div>
          </div>

        </div>

      );
    }else{
      return (
        <Redirect to={'/'}/>
      );
    }

  }
}

export default Landing;